/* eslint-disable no-unused-vars */
import React from 'react';
import { graphql } from 'gatsby';
import {
  Link,
  FormattedMessage,
  FormattedDate,
  navigate,
  intl,
} from 'gatsby-plugin-intl';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Chip from '@material-ui/core/Chip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
const _ = require('lodash');
// or
import { GridContainer } from '@jackywxd/shared-material-ui';
import { GridItem } from '@jackywxd/shared-material-ui';
import { Parallax } from '@jackywxd/shared-material-ui';
import { CustomInput } from '@jackywxd/shared-material-ui';
import indexStyles from 'assets/jss/material-kit-react/views/landingPage';
import Layout from 'components/Layout';
import Button from 'components/CustomButtons';

const useStyles = makeStyles(indexStyles);

const Tags = ({ tags, section }) =>
  tags ? (
    <>
      {tags.map(tag => (
        <Chip
          variant="outlined"
          key={tag}
          label={tag}
          clickable
          onClick={() => {
            navigate(`/${section}/tags/${kebabCase(tag)}`);
          }}
        />
      ))}
    </>
  ) : (
    <div></div>
  );

const Prev = ({ section, previous, lgScreen }) =>
  previous ? (
    <>
      <Button
        justIcon={lgScreen ? false : true}
        size="lg"
        color="transparent"
        startIcon={lgScreen ? <ArrowBackIcon /> : null}
        onClick={() => {
          navigate(`/${section}/${_.kebabCase(previous.title)}`);
        }}
      >
        {lgScreen ? `${previous?.title}` : <ArrowBackIcon />}
      </Button>
    </>
  ) : (
    <div></div>
  );

const Next = ({ section, next, lgScreen }) =>
  next ? (
    <>
      <Button
        justIcon={lgScreen ? false : true}
        size="lg"
        color="transparent"
        endIcon={lgScreen ? <ArrowForwardIcon /> : null}
        onClick={() => {
          navigate(`/${section}/${_.kebabCase(next.title)}`);
        }}
      >
        {lgScreen ? `${next?.title}` : <ArrowForwardIcon />}
      </Button>
    </>
  ) : (
    <div></div>
  );

const BlogPostTemplate = ({ data, pageContext }) => {
  const classes = useStyles();
  const lgScreen = useMediaQuery('(min-width:500px)');
  const post = data?.markdownRemark;
  const banner = post?.frontmatter?.banner
    ? post?.frontmatter?.banner?.childImageSharp.fluid.src
    : null;

  const { previous, next, slug, section } = pageContext;
  return (
    <Layout>
      <Parallax
        small
        filter
        image={banner ? banner : null}
        style={{ background: 'rgba(66, 133, 243, 0.6)' }}
      >
        <div className={classes.container}>
          <GridItem>
            <GridContainer justify="center" alignItems="center">
              <div style={{ textAlign: 'center' }}>
                <h1 color="secondary" className={classes.title}>
                  {post?.frontmatter.title}
                </h1>
                <p>
                  <FormattedMessage
                    id="help.timeToRead"
                    defaultMessage="Last updated: {date} - {timeToRead} min Read"
                    values={{
                      date: post?.frontmatter.date,
                      timeToRead: post?.timeToRead,
                    }}
                  />{' '}
                  {/* <Link
                    style={{ color: 'inherit' }}
                    to={`/${section}/categories/${kebabCase(
                      post.frontmatter.category
                    )}`}
                  >
                    {post.frontmatter.category}
                  </Link> */}
                </p>
              </div>
            </GridContainer>
          </GridItem>
        </div>
      </Parallax>
      <main
        className={
          lgScreen ? classNames(classes.main, classes.mainRaised) : classes.main
        }
      >
        <GridContainer justify="center" alignItems="center">
          <GridItem xs={12} sm={10} md={8}>
            <Box
              style={{ textAlign: 'initial', fontSize: '1rem', margin: 5 }}
              color="text.primary"
              dangerouslySetInnerHTML={{ __html: post?.html }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <p>
                <FormattedMessage id="help.tag" defaultMessage="Tags:" />{' '}
              </p>
              <Tags tags={post?.frontmatter?.tags} section={section} />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                alignContent: 'center',
              }}
            >
              <Prev
                previous={previous ? { slug, title: previous } : null}
                section={section}
                lgScreen={lgScreen}
              />
              <Next
                next={next ? { slug, title: next } : null}
                section={section}
                lgScreen={lgScreen}
              />
            </div>
          </GridItem>
        </GridContainer>
      </main>
    </Layout>
  );
};

BlogPostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query($slug: String!, $language: String!) {
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { lang: { eq: $language } }
    ) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        lang
        title
        date(formatString: "DD.MM.YYYY")
        description
        slug
        category
        tags
        banner {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      timeToRead
    }
  }
`;
